<template>
  <div class="table-item-orders">
    <base-table :columns="columns" :list="paginList" class="table-item-orders__table">
      <base-table-row v-for="item in paginList" :key="item.id" :item="item" :columns="columns">
        <template #name>
          <div class="table-item-orders__table-img">
            <img
              :src="'https://bill.rusonyx.ru/manimg/common/plugin-logo/billmanager-plugin-pmsberbank.png'"
              alt=""
              class="right"
            />
            {{ item.name }}
          </div>
        </template>
        <template #maxamount>
          {{ maxamount(item)[0] }} <br />
          {{ maxamount(item)[1] }}
        </template>
        <template #status>
          <main-label color="success" theme="plain">
            {{ item.status }}
          </main-label>
        </template>
        <template #more>
          <!--          <plain-button color="primary" class="l-col link-center" @click="del(item)">-->
          <plain-button color="primary" class="link-center" @click="del(item)">
            {{ $t('del') }}
          </plain-button>
        </template>
      </base-table-row>
    </base-table>
    <new-pagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="list.length"
      type="small"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable.vue';
import BaseTableRow from '@/components/Table/BaseTableRow.vue';
import MainLabel from '@/components/Labels/MainLabel.vue';
import NewPagination from '@/components/Pagination/NewPagination';
import pagination from '@/mixins/pagination';
export default {
  name: 'TableItemList',
  components: {
    BaseTable,
    BaseTableRow,
    MainLabel,
    NewPagination,
  },
  mixins: [pagination],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stepOptions: [5, 10, 25, 50, 100, 'all'],
      pagin: {
        start: 0,
        step: 5,
      },
    };
  },
  computed: {
    /*paginList() {
      if (this.pagin.step === 'all') return this.list;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.list.slice(start, end);
    },*/
    grouppedList() {
      const collection = {};
      this.list.reduce((acc, item) => {
        if (!acc[item.id]) acc[item.id] = { list: [], cost: 0 };
        acc[item.id].list.push(item);
        acc[item.id].cost += item.service.cost;
        return acc;
      }, collection);
      return collection;
    },
  },
  mounted() {},
  methods: {
    maxamount(item) {
      return item.maxamount.split('<br/>');
    },
    del(item) {
      this.$emit('click-more', item);
    },
    /*setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },*/
  },
};
</script>
<i18n>
{
  "ru": {
    "del": "Удалить"

  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.right {
 margin-right: 1rem;
}
.table-item-orders {
  &__table {
    +breakpoint(md-and-up) {
      max-width: calc(100% - 146px);
    }
    &-img {
      display:flex;
      flex-direction: row;
      align-items: center;

    }
  }
  &__pagination {
    margin-top: 1.5rem;
  }
}
</style>
