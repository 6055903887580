<template>
  <div class="promise-payment">
    <transition name="fade" mode="out-in">
      <base-loader v-if="isLoading" />
      <main-card v-else-if="configurator" class="promise-payment__card">
        <div class="promise-payment__form">
          <div
            v-if="fields && fields.info && fields.info.label"
            class="promise-payment__text standart-text"
          >
            <order-config
              v-if="step === 1"
              :period="12"
              :configurator="configurator"
              @change="updateConfig"
              @destroy="resetConfig"
              @init="updateConfig"
            />
            <component :is="item.component" v-for="(item, idx) in infoMsg" :key="idx">
              <template v-if="item.text && step === -1">
                <p v-html="item.text"></p>
              </template>
            </component>
            <table-item-list
              v-if="step === -1"
              :list="list?.methodlist"
              :columns="tableHead"
              class="promise-payment__list"
              @click-more="clickOff($event)"
            ></table-item-list>
          </div>
        </div>
        <template #footerEnd>
          <base-button :loading="isSending" @click="step < 1 ? send() : sendPayment()">
            {{ step > 1 ? $t('continue') : $t('submit') }}
          </base-button>
        </template>
      </main-card>
    </transition>
  </div>
</template>

<script>
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox.vue';
import billMgrActions from '@/mixins/billmgr/billMgrToolActions';
import qs from 'qs';
import wizardPay from '@/mixins/billmgr/wizardPay';
import OrderConfig from '@/components/Configurator/OrderConfig';
import TableItemList from '@/layouts/Billing/pages/Main/views/AutoPayment/TableItemList';
import BaseConfigurator from '@/models/base/BaseConfigurator';
import redirectLink from '@/mixins/redirectLink';
export default {
  name: 'AutoPayment',
  components: {
    OrderConfig,
    BaseCheckbox,
    TableItemList,
  },
  mixins: [billMgrActions, wizardPay, /*wizard,*/ redirectLink],
  data() {
    return {
      func: 'payment.recurring.settings',
      startFunc: 'payment.recurring.add.paymethod',
      config: null,
      list: [],
      isLoading: false,
      isSending: false,
      amount: '',
      formData: null,
      configurator: {},
      formDataBackup: null,
      selectAll: null,
      trueValue: 'on',
      // step: 0,
      falseValue: 'off',
      isEmpty: false,
      tableHead: [
        {
          key: 'name',
          // label: this.$t('tableHead.server'),
          style: {
            //flex: '1 1 auto',
            width: '130px',
            maxWidth: '230px',
          },
        },
        {
          key: 'maxamount',
          // label: this.$t('tableHead.id'),
          style: {
            width: '180px',
          },
        },
        {
          key: 'status',
          // label: this.$t('tableHead.status'),
          style: {
            width: '56px',
          },
        },
        {
          key: 'more',
          style: {
            width: '24px',
          },
        },
      ],
    };
  },
  computed: {
    fields() {
      if (!this.config) return null;
      return this.config.customfields;
    },
    lists() {
      if (!this.config) return null;
      return this.config.list;
    },
    payers() {
      return this.$store.state.moduleBilling.modulePayers.list[0];
    },
    infoMsg() {
      if (!this.fields || !this.fields.info || !this.fields.info.label) return [];
      let splitted = '';
      if (this.config && this.config.model && this.config.model.info) {
        splitted = this.config.model.info.split('<br/>');
      } else {
        splitted = this.fields.info.label.split('<br/>');
      }
      const list = splitted.reduce((acc, item, idx) => {
        if (item[0] === '-') {
          if (acc[idx - 1].component !== 'ul') {
            acc.push({ component: 'ul', list: [item.substr(1)] });
          } else {
            acc[idx - 1].list.push(item.substr(1));
          }
        } else acc.push({ component: 'p', text: item });
        return acc;
      }, []);

      return list;
    },
  },
  watch: {},
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.isLoading = true;
      const params = {
        func: this.func,
      };
      this.fetchBillMgrAction(params)
        .then(async data => {
          let res = data;
          this.formData = Object.assign({}, data.model);
          if (res && res.ok && res.ok.type && res.ok.type === 'form' && res.ok.v) {
            const v = res.ok.v;
            const payload = qs.parse(v);
            res = await this.getInfo(payload);
          }
          this.configurator = new BaseConfigurator({
            customfields: res.fields,
            model: res.model,
            slist: res.list || [],
            list: res.list || [],
          });
          this.config = {
            customfields: res.customfields || res.fields,
            model: res.model || {},
            list: res.list || {},
            slist: res.slist || {},
            pages: res.pages || [],
          };
          this.list = data.list;
        })
        .finally(() => (this.isLoading = false));
    },
    sendPayment() {
      this.isSending = true;
      const params = {
        ...this.formData,
        paymethod_name: 'Банковская карта',
        profile: this.payers.id,
        profiletype: this.payers.profiletype_orig,
        func: this.func,
      };
      delete params['snext'];
      this.sendBillMgrAction(params)
        .then(data => {
          this.handleRedirect(data.ok);
          // .then(data => {});
        })
        .then(async () => {
          this.func = 'payment.recurring.settings';
          setTimeout(() => {
            this.fetch();
            this.isSending = false;
          }, 1000);
        });
    },
    send() {
      console.log(this.step);
      this.step = 1;
      const params = {
        ...this.formData,
        sok: 'ok',
        clicked_button: 'add',
        elid: '',
        newface: 'none',
        show_metadata: 'none',
        func: this.func,
      };
      this.isSending = true;
      this.sendBillMgrAction(params)
        .then(async data => {
          let res = data;
          this.formData = Object.assign({}, data.model);
          if (res && res.ok && res.ok.type && res.ok.type === 'form' && res.ok.v) {
            const v = res.ok.v;
            const payload = qs.parse(v);
            res = await this.getInfo(payload);
          }
          this.configurator = new BaseConfigurator({
            customfields: res.fields,
            model: res.model,
            slist: res.list || [],
            list: res.list || [],
          });
          this.config = {
            customfields: res.customfields || res.fields,
            model: res.model || {},
            list: res.list || {},
            slist: res.slist || {},
            pages: res.pages || [],
          };
        })
        // .finally(() => (this.isSending = false));
        .finally(() => {
          this.isSending = false;
          this.func = 'payment.recurring.add.pay';
          // this.startWizard();
          // this.step = 2;
        });
    },
    reset() {
      this.config = null;
      this.formData = null;
      this.formDataBackup = null;
    },
    resetConfig() {
      this.configData = null;
      this.costDetails = null;
    },
    getInfo(params) {
      return this.$store.dispatch('fetchBillMgrToolAction', params).catch(e => {
        this.showError(e);
      });
    },
    updateConfig(data) {
      // console.log(data);
      this.formData = { ...data };
    },
    init() {
      this.initSelectAll();
    },
    initSelectAll() {
      this.selectAll = this.listComponents.reduce((acc, field) => {
        if (field.selectAll) {
          acc[field.name] = {
            list: field.selectAll,
            value: false,
          };
        }
        return acc;
      }, {});
    },
    clickOff(event) {
      const params = {
        func: 'payment.recurring.settings',
        sok: 'ok',
        clicked_button: 'stop',
        elid: '',
        id: event.id,
        newface: 'none',
        show_metadata: 'none',
      };
      this.isSending = true;
      this.sendBillMgrAction(params)
        .then(async data => {
          // console.log(data);
          this.fetch();
        })
        .finally(() => (this.isSending = false));
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "submit": "Редактировать"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.promise-payment {
  &__card {
    +breakpoint(md-and-up) {
    width: 100%
      //max-width: 40rem;
    }
  }

  &__form {
    +breakpoint(sm-and-up) {
      margin-bottom: 1.25rem;
    }
  }

  &__list {
    margin-top: 1.5rem;

    &-head {
      margin-bottom: 1.5rem;

      +breakpoint(sm-and-up) {
        flexy(space-between, center);
      }
    }

    &-selectall {
      margin-top: 0.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1rem;
      }
    }

    &-option {
      +breakpoint(sm-and-up) {
        flexy(space-between, center);
      }

      & + & {
        margin-top: 1.25rem;
      }
    }

    &-info {
      flex: 0 0 auto;

      +breakpoint(ms-and-down) {
        margin-top: 0.5rem;
      }

      +breakpoint(sm-and-up) {
        text-align: right;
        margin-left: 1rem;
      }
    }

    &-expire {
      margin-bottom: 2px;
    }
  }
}
</style>
